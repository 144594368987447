<template>
  <div
    v-if="!close"
    class="modal"
  >
    <div class="modal__container">
      <div class="modal__new">
        <div class="modal__new__close">
          <button @click.prevent="$emit('closeParty')">
            <img
              src="@/assets/img/icons/close.svg"
              alt=""
            />
          </button>
        </div>
        <div class="modal__new__title">{{ $t("hiddenModal.title") }}</div>
        <!-- <div class="modal__new__text">{{ $t("hiddenModal.text") }}</div> -->
        <div class="modal__new__descr">{{ $t("hiddenModal.descr") }}</div>
        <div
          v-for="(bonus, index) in bonuses"
          :key="index"
          class="modal__pros"
        >
          <img
            :src="bonus.img"
            alt=""
          />
          <p>{{ $t(`hiddenModal.bonuses.${bonus.txt}`) }}</p>
        </div>
        <button
          class="modal__new__button"
          @click.prevent="$emit('closeParty')"
        >
          {{ $t("hiddenModal.btn") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { actionTypes } from "@/store"
export default {
  name: "AppModalParty",
  data() {
    return {
      close: false,
      bonuses: [
        {
          img: require("@/assets/img/icons/percent.svg"),
          txt: "bonuse-1",
        },
        {
          img: require("@/assets/img/icons/bonus.svg"),
          txt: "bonuse-2",
        },
        {
          img: require("@/assets/img/icons/sale.svg"),
          txt: "bonuse-3",
        },
      ],
    }
  },
  computed: {
    ...mapState(["isAuthActive"]),
  },
  methods: {
    ...mapActions({
      openIsAuthActive: actionTypes.changeIsAuthActive,
    }),
  },
}
</script>

<style
  lang="scss"
  scoped
>
@media (max-width: 768px) {
  .modal__container {
    height: 100%;
  }
}
</style>
