import axios from "@/plugins/axiosUninterseptor"

export const sendRegPhone = async (data) => {
  const response = await axios.post(`/magnumclub/checkPhone`, data)
  return response
}
export const sendRegCode = async (data) => {
  const response = await axios.post(`/magnumclub/registration`, data)
  return response
}
export const sendRegRefresh = async (data) => {
  const response = await axios.post(`/magnumclub/refresh`, data)
  return response
}
export const getRegJoin = async (token) => {
  let config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.post(`/magnumclub/join`, {}, config)
  return response
}
export const getRegAuth = async (token) => {
  let config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`/magnumclub/clubs`, config)
  return response
}
export const getRegProf = async (token) => {
  let config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`/magnumclub/profile`, config)
  return response
}
