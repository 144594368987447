<template>
  <div
    class="modal"
    @click.self="openIsAuthActive"
  >
    <div
      class="modal__container"
      @click.self="openIsAuthActive"
    >
      <app-modal-phone
        v-if="isShowPhone"
        @numberSubmitted="numberSubmit"
        :ruleFile="getRuleFile"
      />
      <app-modal-code
        :phone="phone"
        v-if="isShowSms"
        @codeSubmitted="checkStatus"
        @sendBack="
          isShowPhone = true
          isShowSms = false
        "
      />
      <app-modal-magnum
        v-if="isMagnum"
        @closeMagnum="closeMagnum()"
        @back="
          isMagnum = false
          isShowSms = true
          isShowPhone = false
        "
      />
      <app-modal-party
        v-if="isParty"
        @closeParty="closeParty()"
      />
    </div>
  </div>
</template>

<script>
import AppModalCode from "../../components/modal/AppModalCode.vue"
import AppModalPhone from "../../components/modal/AppModalPhone.vue"
// import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapActions, mapState } from "vuex"

import { actionTypes } from "@/store"
import AppModalMagnum from "./AppModalMagnum.vue"
import AppModalParty from "./AppModalParty.vue"
export default {
  name: "AppModalRegistration",
  components: {
    // ValidationProvider,
    AppModalPhone,
    // ValidationObserver,
    AppModalCode,
    AppModalMagnum,
    AppModalParty,
  },
  data() {
    return {
      phone: "",
      user: {
        cartNumber: "",
        gender: "",
        phone: "",
        first_name: "",
        last_name: "",
        patronymic: "",
        birthday: "",
        region: "",
        email: "",
      },
      birthday_error: true,
      timer: 60,
      sms: {
        code_1: "",
        code_2: "",
        code_3: "",
        code_4: "",
      },
      currentRefs: 0,
      isShowSms: false,
      isShowPhone: true,
      isMagnum: false,
      isParty: false,
      authToken: null,
      errorsFromBackend: {
        cardNumber: null,
      },
    }
  },
  props: {
    ruleFile: {
      type: String,
      default: "",
    },
  },
  watch: {
    "user.cartNumber": {
      deep: true,
      handler() {
        this.errorsFromBackend.cardNumber = null
      },
    },
  },
  computed: {
    ...mapState(["isAuthActive"]),

    smsInputRefs() {
      return [
        this.$refs.firstNumber,
        this.$refs.secondNumber,
        this.$refs.thirdNumber,
        this.$refs.fourthNumber,
      ]
    },
    getPhone() {
      return this.user.phone.replace(/[^0-9]/g, "")
    },
  },
  mounted() {
    if (this.timer === 0) {
      this.isShowAgainBtn = true
    }
  },
  methods: {
    numberSubmit(a) {
      this.phone = a
      this.isShowPhone = false
      this.isShowSms = true
    },
    ...mapActions({
      openIsAuthActive: actionTypes.changeIsAuthActive,
    }),
    closeParty() {
      if (this.isAuthActive?.page) {
        this.$router.push({
          path: this.getTo("heating-systems/bat"),
          query: this.getQuery(),
        })
        localStorage.setItem("bat", "bat")
        this.openIsAuthActive()
      } else {
        this.openIsAuthActive()
        this.$router.go()
      }
    },
    closeMagnum() {
      this.openIsAuthActive()
    },
    checkStatus(s) {
      if (s) {
        this.isParty = true
      } else {
        this.isShowPhone = false
        this.isShowSms = false
        this.isMagnum = true
      }
    },
    getTo(to) {
      const locale = this.$i18n.locale

      if (typeof to !== "string") {
        // this.to.path = `${locale}/${this.to.path}`;
        return to
      }
      return `/${locale}/${to.replace(/^\/|\/$/g, "")}`
    },
    getQuery() {
      return { city_id: this.$route.query.city_id }
    },
    focusPhone() {
      this.isShowPhoneSpan = true
    },
    close() {
      this.$emit("close")
      document.querySelector("body").classList.remove("no-scroll")
    },
  },
}
</script>
<style
  lang="scss"
  scoped
>
@media (max-width: 768px) {
  .modal {
    padding: 0;

    &__container {
      width: 100%;
      height: 100vh;
      background-color: #fff;
    }
  }
}
</style>
