<template>
  <div
    v-if="!close"
    class="modal"
  >
    <div class="modal__container">
      <div class="modal__new">
        <div class="modal__new__close__sms">
          <div
            class="back"
            @click.prevent="$emit('back')"
          >
            <svg
              width="7"
              height="16"
              viewBox="0 0 7 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 14.5697L1.0617 16L7 8L1.0617 0L0 1.4303L4.87661 8L0 14.5697Z"
                fill="currentColor"
              />
            </svg>
            <p>{{ $t("modals.back") }}</p>
          </div>
          <div
            class="close"
            @click="$emit('closeMagnum')"
          >
            <img
              src="@/assets/img/icons/close.svg"
              alt=""
            />
          </div>
        </div>
        <div class="modal__new__title">{{ $t("modals.notMagnum") }}</div>
        <div class="modal__new__descr">
          {{ $t("modals.forMagnum") }}
        </div>
        <div class="modal__links">
          <img
            class="pic__mobile"
            src="@/assets/img/modal-not-member/clubMagnum.png"
            alt=""
          />
          <div class="modal__links__market">
            <img
              src="@/assets/img/modal-not-member/apple.png"
              alt=""
            />
            <img
              src="@/assets/img/modal-not-member/google.png"
              alt=""
            />
            <img
              src="@/assets/img/modal-not-member/huawei.png"
              alt=""
            />
          </div>
          <img
            class="pic__desc"
            src="@/assets/img/modal-not-member/qr.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppModalMagnum",
  data() {
    return {
      close: false,
    }
  },
}
</script>

<style
  lang="scss"
  scoped
>
@media (max-width: 768px) {
  .modal__container {
    height: 100%;
  }
}
</style>
