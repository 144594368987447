<template>
  <div class="modal__new">
    <div class="modal__new__close__sms">
      <div
        class="back"
        @click="$emit('sendBack')"
      >
        <svg
          width="7"
          height="16"
          viewBox="0 0 7 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 14.5697L1.0617 16L7 8L1.0617 0L0 1.4303L4.87661 8L0 14.5697Z"
            fill="currentColor"
          />
        </svg>
        <p>Назад</p>
      </div>
      <div
        class="close"
        @click="openIsAuthActive"
      >
        <img
          src="@/assets/img/icons/close.svg"
          alt=""
        />
      </div>
    </div>
    <div class="modal__new__title">{{ $t("smsModal.title") }}</div>
    <div
      class="modal__new__descr"
      v-if="phone && $i18n.locale === 'ru'"
    >
      Мы отправили сообщение на номер <br />
      +7 {{ phone }}
    </div>
    <div
      class="modal__new__descr"
      v-if="phone && $i18n.locale === 'kz'"
    >
      Біз +7 {{ phone }} нөмірінее хабарлама жібердік
    </div>
    <p
      v-if="wrong"
      class="wrong__code"
    >
      введен неверный код
    </p>
    <form
      class="club-sms"
      autocomplete="off"
    >
      <input
        v-model="sms.code_1"
        ref="firstNumber"
        placeholder="_"
        v-mask="'#'"
        type="number"
        class="club-sms-input"
        @keyup="addInput"
        :class="{ focus: sms.code_1.length > 0 }"
      />
      <input
        ref="secondNumber"
        v-model="sms.code_2"
        maxlength="1"
        type="number"
        v-mask="'#'"
        placeholder="_"
        class="club-sms-input"
        @keyup="addInput"
        :class="{ focus: sms.code_2.length > 0 }"
      />
      <input
        v-model="sms.code_3"
        v-mask="'#'"
        ref="thirdNumber"
        type="number"
        maxlength="1"
        placeholder="_"
        class="club-sms-input"
        @keyup="addInput"
        :class="{ focus: sms.code_3.length > 0 }"
      />
      <input
        v-model="sms.code_4"
        ref="fourthNumber"
        maxlength="1"
        type="number"
        placeholder="_"
        class="club-sms-input"
        @keyup="addInput"
        v-mask="'#'"
        :class="{ focus: sms.code_4.length > 0 }"
      />
    </form>
    <button
      class="modal__new__button"
      @click="sendCode"
      :disabled="getSMS().length === 4 ? false : true"
    >
      {{ $t("smsModal.btn") }}
    </button>
    <div class="modal__send">
      <p
        v-show="elementVisible"
        class="hide__element"
      >
        {{ $t("smsModal.again.text1") }} {{ timerCount }} {{ $t("smsModal.again.text2") }}
      </p>
      <button
        v-show="!elementVisible"
        @click="sendAgain"
      >
        <span>{{ $t("smsModal.again.error") }} </span>
      </button>
      <p>{{ $t("smsModal.problem") }}</p>
      <a
        class="problem__call"
        :href="'tel:' + $t('smsModal.call').slice(19)"
        >{{ $t("smsModal.call") }}</a
      >
    </div>
    <div
      v-if="isloading"
      class="loading"
    >
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import { sendRegCode, sendRegRefresh, getRegJoin, getRegAuth, getRegProf } from "@/api/registration"
import { mapActions } from "vuex"
import { actionTypes } from "@/store"
import { sendRegPhone } from "@/api/registration"

export default {
  props: {
    phone: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      container: document.getElementsByClassName("club-sms")[0],
      timerCount: 20,
      elementVisible: true,
      close: false,
      isloading: false,
      time: 0,
      wrong: false,
      sms: {
        code_1: "",
        code_2: "",
        code_3: "",
        code_4: "",
      },
    }
  },
  created() {
    if (this.timerCount > 0) {
      this.time = setTimeout(() => (this.elementVisible = false), 20000)
      return this.time
    }
  },
  watch: {
    timerCount: {
      handler(value) {
        console.log(this.timerCount)
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--
          }, 1000)
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    ...mapActions({
      openIsAuthActive: actionTypes.changeIsAuthActive,
    }),
    sendAgain() {
      sendRegPhone({
        phone: this.phone.replace(/[^+\d]/g, ""),
      }).then(() => {
        this.timerCount = 20
        this.elementVisible = true
        if (this.timerCount > 0) {
          this.time = setTimeout(() => (this.elementVisible = false), 20000)
          return this.time
        }
      })
    },
    sendCode() {
      this.wrong = false
      this.isloading = true
      let code = this.getSMS()
      if (code.length === 4) {
        sendRegCode({
          code: Number(code),
          phone: this.phone.replace(/[^+\d]/g, ""),
        })
          .then((res) => {
            sendRegRefresh({
              refresh_token: res.data.refresh_token,
            })
              .then((res) => {
                if (!localStorage.getItem("isAdult")) localStorage.setItem("isAdult", true)
                this.$cookies.set("refresh_token", res.data.refresh_token, "3m", "/")
                this.$cookies.set("token", res.data.auth_token, "1d", "/")
                getRegAuth(this.$cookies.get("token"))
                  .then((res) => {
                    if (res.data.length > 0) {
                      getRegProf(this.$cookies.get("token")).then((res) => {
                        if (res.data.first_name) {
                          this.$cookies.set(
                            "profile",
                            {
                              first_name: res.data.first_name,
                              last_name: res.data.last_name,
                              gender: res.data.gender,
                              phone: res.data.phone,
                              birthday: res.data.birthday,
                            },
                            "3m",
                            "/",
                          )
                          this.$cookies.set("dilha", "cool men")
                          this.$emit("codeSubmitted", true)
                        } else {
                          this.$emit("codeSubmitted", false)
                        }
                      })
                    } else {
                      getRegJoin(this.$cookies.get("token"))
                        .then(() => {
                          getRegProf(this.$cookies.get("token"))
                            .then((res) => {
                              if (res.data.first_name) {
                                this.$cookies.set("profile", {
                                  first_name: res.data.first_name,
                                  last_name: res.data.last_name,
                                  gender: res.data.gender,
                                  phone: res.data.phone,
                                  birthday: res.data.birthday,
                                })
                                this.$emit("codeSubmitted", true)
                              } else {
                                this.$emit("codeSubmitted", false)
                              }
                            })
                            .catch(() => {
                              this.isloading = false
                            })
                        })
                        .catch(() => {
                          this.isloading = false
                          getRegProf(this.$cookies.get("token"))
                            .then((res) => {
                              console.log("profile", res)
                              if (res.data.first_name) {
                                this.$cookies.set("profile", {
                                  first_name: res.data.first_name,
                                  last_name: res.data.last_name,
                                  gender: res.data.gender,
                                  phone: res.data.phone,
                                  birthday: res.data.birthday,
                                })
                                this.$emit("codeSubmitted", true)
                              } else {
                                this.$emit("codeSubmitted", false)
                              }
                            })
                            .catch(() => {
                              this.isloading = false
                            })
                        })
                    }
                    const dataLayer = window.dataLayer || []
                    dataLayer.push({ event: "login_success" })
                  })
                  .catch(() => {
                    this.isloading = false
                  })
              })
              .catch(() => {
                this.isloading = false
              })
          })
          .catch(() => {
            this.wrong = true
            this.isloading = false
          })
      }
    },
    addInput(e) {
      if (!isNaN(Number(e.target.value)) && e.target.value.length !== 0) {
        if (e.target.nextElementSibling) {
          e.target.nextElementSibling.focus()
        } else if (this.getSMS().length !== 4) {
          console.log("")
        } else {
          // setTimeout(() => {
          //   this.sendCode()
          // }, 300)
        }
      } else if (e.key === "Backspace") {
        if (e.target.previousElementSibling) {
          e.target.previousElementSibling.focus()
        } else {
          this.sms.code_1 = ""
          this.sms.code_2 = ""
          this.sms.code_3 = ""
          this.sms.code_4 = ""
        }
      } else if (this.getSMS().length !== 4) {
        console.log("")
      } else {
        setTimeout(() => {
          e.target.value = ""
        }, 100)
      }
    },
    getSMS() {
      return (
        String(this.sms.code_1) +
        String(this.sms.code_2) +
        String(this.sms.code_3) +
        String(this.sms.code_4)
      )
    },
    sumCode(items) {
      let sum = ""
      for (let item of Object.values(items)) {
        sum += item
      }
      return sum
    },
    deleteInput(e) {
      if (e.code === "Backspace") {
        this.currentRefs--
        this.smsInputRefs[this.currentRefs]?.focus()
      }
    },
  },
  smsInputRefs() {
    return [
      this.$refs.firstNumber,
      this.$refs.secondNumber,
      this.$refs.thirdNumber,
      this.$refs.fourthNumber,
    ]
  },
  setAgain() {
    this.elementVisible = true
    this.time = 20
  },
  container(e) {
    var target = e.srcElement || e.target
    var maxLength = parseInt(target.attributes["maxlength"].value, 10)
    var myLength = target.value.length
    if (myLength >= maxLength) {
      var next = target
      while ((next = next.nextElementSibling)) {
        if (next == null) break
        if (next.tagName.toLowerCase() === "input") {
          next.focus()
          break
        }
      }
    }
    // Move to previous field if empty (user pressed backspace)
    else if (myLength === 0) {
      var previous = target
      while ((previous = previous.previousElementSibling)) {
        if (previous == null) break
        if (previous.tagName.toLowerCase() === "input") {
          previous.focus()
          break
        }
      }
    }
  },
}
</script>

<style
  lang="scss"
  scoped
>
@media (max-width: 500px) {
  .modal {
    &__container {
      height: 100%;
      display: flex;
    }
    padding: 0;
  }
}
.wrong__code {
  color: #ff8200;
  font-size: 14px;
}
.modal__send {
  color: #999999;
  font-size: 14px;
  span {
    color: #8344d6;
  }
  button {
    margin-bottom: 16px;
  }
}
.problem__call {
  color: #8344d6;
}
.loading {
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal__new {
  position: relative;
}
.loader {
  border: 8px solid #b29fcb; /* Light grey */
  border-top: 8px solid #8344d6; /* Blue */
  border-bottom: 8px solid #8344d6; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  // 30%{ transform: rotate(60deg);}
  // 50%{ transform: rotate(160deg);}
  // 70%{ transform: rotate(260deg);}
  // 60%{transform: rotate(10deg);}
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .club-sms {
    margin: 10px 0 40px;
  }
}
@media (max-width: 500px) {
  .modal__new {
    max-width: none;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 60%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
}
</style>

eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfaWQiOjExMDIzNDcsImV4cCI6MTY3NTQ4ODkyOX0.VP9pxvSEL-GTB9c7B0au5LoiAjVZJJ3ATC6s3_5wXxnFHTn6GN8ETicbiOhaRlnz_KPhPkhy38mv64VbrukRemtxcLW-KfVnzk9mxTQ9eaL22BBuadvZ_aOBKZqvJvXEBRQkkGJauZEzau5w14GwFmmIaAL4EWuLIBDIDr8SdzXJKpdM6ZMmf8YVTOHQ3622eZjr8b8xt-SBJ1RbTzSfi2iagXKmUkYi2iIpT1_KRkGuf5VlVnO7O_OABphex_2X6qL-jAbjBt3HLuQqLwwFKKyvWRJ2FQoGoZWNy9q3YoQrzEKabKevYMhUyKWEe7FU-DpC-64NPySYPXd4N9rNX2Ei8QygiIkGDpT60IvpDBVXjFGJ-dYJSM-JeBanCeO01ATOUVAReVVex3nd-tj2VBG9IKQWn8hVGHwTdC0YHLRRUHHdsiMAEMrjuf_NpFM3jDwV2vsG6eCRCRMIu7zrN28HHugnW16LrNXLOANGkdgfZ7gp3baKAfM39_QNSTPBSJ5cvU-4zYYPKGiiPTOkDqxZx1RqXrHPDcqm_qUVCkC5FNQyBh1grQdnlW06K0hhuG-T9miGUkvwCThqiV9WYUSwdytk0gawd2L_OmkDzIhBw9pOpZFtg5-MkKYGytY-CtXQv5YBf7CfCizt795YJzbxdqqB9lxJjNW0BFukRJQ
