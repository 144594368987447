<template>
  <form
    class="modal__new"
    @submit.prevent="submitNumber"
  >
    <div class="modal__new__close">
      <div
        style="max-width: 34px; cursor: pointer"
        @click="openIsAuthActive"
      >
        <img
          src="@/assets/img/icons/close.svg"
          alt=""
        />
      </div>
    </div>
    <div class="modal__new__title">{{ $t("phoneModal.title") }}</div>
    <div class="modal__new__descr">{{ $t("phoneModal.descr") }}</div>
    <div class="modal__new__input">
      <span>+7</span>
      <input
        type="text"
        v-mask="' (###) ### ## ##'"
        v-model="phone"
        :placeholder="$t('phoneModal.input')"
        required
      />
    </div>
    <input
      class="modal__new__button"
      type="submit"
      :value="$t('smsModal.btn')"
      :class="{
        gray: phone.replace(/[^+\d]/g, '').length < 10 || !conditions.age || !conditions.rule,
      }"
    />
    <div class="modal__new__condition">
      <input
        id="age"
        type="checkbox"
        v-model="conditions.age"
        required
      />
      <label for="age">{{ $t("phoneModal.age") }}</label>
    </div>
    <div class="modal__new__condition">
      <input
        id="rules"
        type="checkbox"
        v-model="conditions.rule"
        required
      />
      <label for="rules"
        >{{ $t("phoneModal.rule.text1") }}
        <span
          ><a
            :href="getRuleFile"
            target="_blank"
            >{{ $t("phoneModal.rule.link") }}</a
          ></span
        >
        {{ $t("phoneModal.rule.text2") }}</label
      >
    </div>
  </form>
</template>

<script>
import { mapActions } from "vuex"
import { actionTypes } from "@/store"
import { sendRegPhone } from "@/api/registration"
import { getRule } from "@/api/rules"

export default {
  name: "AppModalPhone",
  methods: {
    ...mapActions({
      openIsAuthActive: actionTypes.changeIsAuthActive,
    }),
    submitNumber() {
      sendRegPhone({
        phone: this.phone.replace(/[^+\d]/g, ""),
      }).then((bruh) => {
        let item = this.phone
        this.$emit("numberSubmitted", item)
        console.log(bruh)
      })
    },
    async loadRules() {
      const response = await getRule()
      this.rulesData = response.data
    },
  },
  data() {
    return {
      close: false,
      phone: "",
      conditions: {
        age: false,
        rule: false,
      },
      rulesData: {},
    }
  },
  mounted() {
    this.loadRules()
  },
  computed: {
    getIsButtonDisabled() {
      return (
        !this.phone ||
        this.phone.replace(/[^+\d]/g, "").length < 10 ||
        !this.conditions.age ||
        !this.conditions.rule
      )
    },
    getRuleFile() {
      if (this.rulesData?.attributes?.file) {
        return this.$helpers.getAbsolutePath(
          this.rulesData?.attributes?.file?.data?.attributes?.url,
        )
      }
      return ""
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
@media (max-width: 500px) {
  .modal {
    padding: 0;
    &__container {
      width: 100%;
      height: 100%;
    }
    &__phone {
      padding: 20px;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
  }
}
.modal__new__condition {
  input[type="checkbox"] {
    accent-color: #8344d6;
  }
}
.gray {
  background-color: gray;
  cursor: not-allowed;
}
</style>
